.monaco-editor {
  padding: 1em 0;
}

.MuiCard-root {
  margin-bottom: 1em;
}

.model-header,
.modal-body,
.modal-footer {
  background: #f8f9fe;
}

.modal-header {
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
}

.modal-footer {
  border-radius: 0 0 6px 6px;
}

.widget-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-profile-stats > div {
  margin: 0;
}

table {
  tbody {
    tr {
      &:hover {
        background: $gray-100;
        cursor: pointer;
      }
    }
  }
}